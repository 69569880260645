<template>
  <div>
    <v-card id="receipt-list">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="row text-right pb-sm-6 pb-5">
          <div class="col-sm-2 pa-2 col-12">
            <!-- filter sms send -->
            <v-select
              v-model="smsSent"
              :items="sendFilters"
              item-value="id"
              item-text="name"
              dense
              outlined
              clearable
              hide-details="auto"
              :placeholder="t('Select SMS Send')"
            ></v-select>
          </div>

          <div class="col-sm-2 pa-2 col-6">
            <!-- filter account -->
            <v-combobox
              v-model="accountFilters"
              :items="accounts"
              item-text="name"
              single-line
              multiple
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Account')"
              :class="{ 'receipt-list-account': $vuetify.breakpoint.smAndUp }"
            ></v-combobox>
          </div>

          <div class="row pa-2">
            <div class="col-6 pr-2">
              <v-autocomplete
                v-model="toggleSelected"
                :items="branchOptions"
                item-text="name"
                item-value="value"
                single-line
                outlined
                dense
                hide-details
                clearable
                :placeholder="t('Select Branch Status')"
                @change="activeToggleChange()"
              ></v-autocomplete>
            </div>

            <div class="col-6 pl-2">
              <!-- filter branch -->
              <v-combobox
                v-model="branchFilters"
                :items="branches"
                item-text="name"
                single-line
                multiple
                outlined
                dense
                hide-details
                clearable
                :placeholder="t('Select Branch')"
                :class="{ 'receipt-list-branch': $vuetify.breakpoint.smAndUp }"
              ></v-combobox>
            </div>
          </div>

          <div class="col-sm-2 col-12 pa-2">
            <!-- filter insurance -->
            <v-combobox
              v-model="insuranceFilters"
              :items="insurances"
              item-text="name"
              single-line
              multiple
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Insurances')"
              :class="{ 'receipt-list-insurances': $vuetify.breakpoint.smAndUp }"
            ></v-combobox>
          </div>
        </div>
      </v-card-text>

      <v-card-text class="row pb-0 pr-0">
        <div class="col-sm-3 col-12 pb-sm-8 pb-1 pl-2 pr-1">
          <!-- Date range -->
          <v-menu
            v-model="isDateMenuOpen"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :append-icon="icons.mdiCalendar"
                :value="dateRangeText"
                readonly
                dense
                outlined
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange"
              color="primary"
              :first-day-of-week="1"
              range
            >
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  color="primary"
                  block
                  @click="filterBySentSms = false; dateRangeChange()"
                >
                  {{ t('Set Dates') }}
                </v-btn>
                <v-btn
                  color="primary"
                  block
                  outlined
                  class="mt-3"
                  @click="filterBySentSms = true; dateRangeChange()"
                >
                  Dates of sent SMS
                </v-btn>
              </div>
            </v-date-picker>
          </v-menu>
        </div>

        <div
          v-if="filterBySentSms"
          class="ml-3 mt-2 mt-sm-5 d-flex"
        >
          <v-icon size="20">
            {{ icons.mdiMessageProcessingOutline }}
          </v-icon>
          <span class="ml-3">
            Filter by sent SMS
          </span>
        </div>

        <v-spacer></v-spacer>

        <div class="d-flex align-center col-sm-5 col-12 pb-sm-8 pb-1 pr-1">
          <!-- search receipt -->
          <v-tooltip
            :left="$vuetify.breakpoint.smAndUp"
            :top="$vuetify.breakpoint.xsOnly"
            :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
            :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
            :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
            max-width="250"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                class="mr-3 hover-pointer"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="25">
                  {{ icons.mdiAlertCircleOutline }}
                </v-icon>
              </v-avatar>
            </template>
            <h3 class="my-2">
              {{ t('How to use the search bar') }}
            </h3>
            <p class="mb-1">
              {{ t('You can search using') }}:
            </p>
            <ul class="mb-2">
              <li>
                {{ t('#Receipt No.') }}
              </li>
              <li>
                {{ t('Insurance Policy No.') }}
              </li>
              <li>
                {{ t('Car Registration No.') }}
              </li>
              <li>
                {{ t('Customer Name') }}
              </li>
            </ul>
          </v-tooltip>
          <v-text-field
            v-model="searchText"
            :append-icon="icons.mdiMagnify"
            single-line
            dense
            outlined
            hide-details
            :placeholder="t('Search Receipt')"
            :class="{ 'receipt-list-search': $vuetify.breakpoint.mdAndUp }"
          ></v-text-field>
        </div>
        <div class="d-flex align-center col-sm-2 col-12 pl-3 pb-sm-8 pb-6 pl-0 pr-1">
          <v-btn
            block
            color="primary"
            @click="searchReceipts"
          >
            <v-icon
              class="pr-2"
              size="30"
            >
              {{ icons.mdiMagnify }}
            </v-icon>
            {{ t('Search') }}
          </v-btn>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="receipt-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="pageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedReceipts"
        :headers="tableColumns"
        :items="receipts"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        hide-default-footer
        show-select
        :disable-sort="true"
        class="text-no-wrap d-none d-sm-block"
        @item-selected="selectedChange($event)"
        @toggle-select-all="toggleAll($event)"
      >
        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center">
            <!-- Send SMS -->
            <v-tooltip
              v-if="can('write', 'Runner') && item.pickup_location_name !== 'Others' && item.pickup_location !== 'NULL'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="isSendSmsDialogVisible = true; receiptSms = item"
                >
                  <v-icon size="18">
                    {{ icons.mdiMessageProcessingOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-if="isAuditor">
                Only send SMS on runner behalf if requested
              </span>
              <span v-else>
                Send SMS
              </span>
            </v-tooltip>
            <v-tooltip
              v-else
              bottom
            >
              <template #activator="{ on }">
                <div v-on="on">
                  <v-btn
                    icon
                    small
                    disabled
                  >
                    <v-icon size="18">
                      {{ icons.mdiMessageProcessingOutline }}
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <span v-if="item.pickup_location_name === 'Others'">
                SMS send disabled on others location
              </span>
              <span v-else-if="item.pickup_location === 'NULL'">
                Pickup location required to send SMS
              </span>
            </v-tooltip>

            <!-- View Insurance Policy -->
            <v-tooltip
              v-if="item.insurance_policy_url !== 'NULL'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  :href="item.insurance_policy_url"
                  target="_blank"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>View insurance policy</span>
            </v-tooltip>
            <v-tooltip
              v-else
              bottom
            >
              <template #activator="{ on }">
                <div v-on="on">
                  <v-btn
                    icon
                    small
                    disabled
                  >
                    <v-icon size="18">
                      {{ icons.mdiHelpCircleOutline }}
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>No insurance policy attached</span>
            </v-tooltip>
          </div>
        </template>

        <!-- Receipt Number -->
        <template #[`item.receipt_number`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'apps-receipt-preview', params: { id: item.id } }"
          >
            {{ item.receipt_number }}
          </router-link>
        </template>

        <!-- Net Payment -->
        <template #[`item.net_payment`]="{item}">
          <div>
            {{ item.net_payment ? `$${item.net_payment}` : 'NULL' }}
          </div>
        </template>

        <!-- Payment Screenshot -->
        <template #[`item.payment_method`]="{item}">
          <div>
            {{ item.payment_method }}
            <v-tooltip
              v-if="item.payment_screenshot_url !== 'NULL'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="isScreenshotDialogVisible = true; receiptScreenshot = item"
                >
                  <v-icon size="18">
                    {{ icons.mdiPaperclip }}
                  </v-icon>
                </v-btn>
              </template>
              <span>View payment screenshot</span>
            </v-tooltip>
          </div>
        </template>

        <!-- Invoice Number -->
        <template #[`item.invoice_number`]="{item}">
          <router-link
            v-if="item.invoice_number !== 'NULL'"
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'apps-invoice-preview', params: { id: item.invoice_id } }"
          >
            {{ item.invoice_number }}
          </router-link>
          <p
            v-else
            class="mb-0"
          >
            NULL
          </p>
        </template>
      </v-data-table>

      <!-- table for mobile view -->
      <v-data-table
        :headers="tableSmColumns"
        :items="receipts"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        :expanded.sync="expanded"
        single-expand
        mobile-breakpoint="0"
        hide-default-footer
        :disable-sort="true"
        class="text-no-wrap d-block d-sm-none"
        @click:row="expandRow"
      >
        <!-- Receipt Number -->
        <template #[`item.receipt_number`]="{item}">
          <div class="table-sm-item primary--text">
            {{ item.receipt_number }}
          </div>
        </template>

        <!-- Last sent -->
        <template #[`item.sms_sent_at`]="{item}">
          <div class="table-sm-item mt-2 text-wrap">
            <div
              v-if="item.pickup_location_name === 'Others'"
              class="font-italic"
            >
              *SMS send disabled on others location
            </div>
            <div
              v-else-if="item.pickup_location === 'NULL'"
              class="font-italic"
            >
              *Pickup location required to send SMS
            </div>
            <div v-else>
              {{ item.sms_sent_at || 'Not sent' }}
            </div>

            <div class="mb-1 font-weight-medium">
              {{ item.pickup_location !== 'NULL' ? item.pickup_location : '' }}
            </div>
            <div class="mb-2">
              <!-- Send SMS -->
              <v-tooltip
                v-if="can('write', 'Runner') && item.pickup_location_name !== 'Others' && item.pickup_location !== 'NULL'"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    x-small
                    :color="item.sms_sent_at === null ? 'primary' : 'secondary'"
                    class="px-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="isSendSmsDialogVisible = true; receiptSms = item"
                  >
                    <v-icon
                      size="16"
                      class="mr-2"
                    >
                      {{ icons.mdiMessageProcessingOutline }}
                    </v-icon>
                    {{ t('Send') }}
                  </v-btn>
                </template>
                <span v-if="isAuditor">
                  Only send SMS on runner behalf if requested
                </span>
                <span v-else>
                  Send SMS
                </span>
              </v-tooltip>
            </div>
          </div>
        </template>

        <!-- Issue Date -->
        <template #[`item.issued_at`]="{item}">
          <div class="table-sm-item">
            {{ item.issued_at }}
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            class="table-expand"
            :colspan="headers.length"
          >
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('Issue Date') }}:</small>
              <small>{{ item.issued_at }}</small>
            </div>
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('Car Registration No.') }}:</small>
              <small>{{ item.car_registration_number }}</small>
            </div>
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('Customer Name') }}:</small>
              <small>{{ item.customer_name }}</small>
            </div>
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('Contact No.') }}:</small>
              <small>{{ item.customer_contact_number }}</small>
            </div>
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('Insurance Premium') }}:</small>
              <small>{{ item.insurance_premium }}</small>
            </div>
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('After Discount') }}:</small>
              <small>{{ item.after_discount }}</small>
            </div>
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('Voucher Discount') }}:</small>
              <small>{{ item.voucher_amount }}</small>
            </div>
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('Voucher Code') }}:</small>
              <small>{{ item.voucher_code }}</small>
            </div>
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('Net Payment') }}:</small>
              <small>{{ item.net_payment }}</small>
            </div>
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('SPD Reference No.') }}:</small>
              <small>{{ item.full_spd_number }}</small>
            </div>
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('Road Tax') }}:</small>
              <small>{{ item.road_tax }}</small>
            </div>
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('Additional Fees') }}:</small>
              <small>{{ item.additional_fees }}</small>
            </div>
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('Payment Method') }}:</small>
              <small>{{ item.payment_method }}</small>
            </div>
            <div class="row justify-space-between font-weight-semibold my-2 px-3">
              <small>{{ t('#Invoice No.') }}:</small>
              <small>{{ item.invoice_number }}</small>
            </div>
            <div class="font-weight-semibold my-2">
              <small>{{ t('General Remarks') }}:</small>
              <div class="text-wrap w-100">
                <small>{{ item.general_remarks }}</small>
              </div>
            </div>
          </td>
        </template>
      </v-data-table>

      <!-- Send SMS dialog -->
      <v-dialog
        v-model="isSendSmsDialogVisible"
        max-width="500"
      >
        <v-card v-if="receiptSms">
          <v-card-title class="d-flex align-center py-3">
            {{ t('SMS Send') }}
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="ml-5"
              @click="isSendSmsDialogVisible = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            {{ t('Send SMS message to contact number') }} <b>673{{ receiptSms.customer_contact_number }}</b> {{ t('to notify their roadtax have arrived') }}.
          </v-card-text>
          <v-card-actions>
            <v-btn
              :color="receiptSms.sms_sent_at === null ? 'primary' : 'secondary'"
              block
              :loading="sendLoading"
              :disabled="sendLoading"
              class="white--text"
              @click="sendRoadtaxMessage"
            >
              <v-icon
                class="me-2"
                left
              >
                {{ icons.mdiMessageProcessingOutline }}
              </v-icon>
              {{ t('Yes, send message') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Payment Screenshot dialog -->
      <v-dialog
        v-model="isScreenshotDialogVisible"
        max-width="1000"
      >
        <v-card v-if="receiptScreenshot">
          <v-card-title class="d-flex align-center py-3">
            <v-row>
              <v-col
                v-if="receiptScreenshot.issued_at"
                cols="12"
                sm="8"
              >
                {{ receiptScreenshot.receipt_number }}_{{ receiptScreenshot.issued_at.split('/').join('-') }}_{{ receiptScreenshot.payment_screenshot_name }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col
                cols="12"
                sm="4"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  class="ml-5"
                  @click="downloadScreenshot(receiptScreenshot)"
                >
                  <v-icon size="22">
                    {{ icons.mdiDownload }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  class="ml-5"
                  :href="receiptScreenshot.payment_screenshot_url"
                  target="_blank"
                >
                  <v-icon size="22">
                    {{ icons.mdiOpenInNew }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  class="ml-5"
                  @click="isScreenshotDialogVisible = false; receiptScreenshot = {}"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <div v-if="receiptScreenshot.payment_screenshot_filetype === 'pdf'">
            <iframe
              class="payment-screenshot-viewer"
              :src="receiptScreenshot.payment_screenshot_url"
            ></iframe>
          </div>
          <div v-else>
            <v-img
              width="100%"
              :src="receiptScreenshot.payment_screenshot_url"
            ></v-img>
          </div>
        </v-card>
      </v-dialog>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="receipt-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="pageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-right">
      <v-btn
        color="primary"
        dark
        :block="$vuetify.breakpoint.xsOnly"
        :loading="loadingPrint"
        :disabled="loadingPrint"
        class="receipt-button mt-5"
        :class="{ 'loading-color': loadingPrint && !$vuetify.theme.dark }"
        @click="printRunnerReport()"
      >
        <v-icon
          v-if="!loadingPrint"
          size="18"
          class="me-2"
        >
          {{ icons.mdiPrinter }}
        </v-icon>
        <span>{{ t('Print runner report') }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiCalendar,
  mdiPrinter,
  mdiMagnify,
  mdiClose,
  mdiFileDocumentOutline,
  mdiHelpCircleOutline,
  mdiPaperclip,
  mdiDownload,
  mdiOpenInNew,
  mdiMessageProcessingOutline,
} from '@mdi/js'
import {
  onUnmounted,
  onMounted,
  ref,
  inject,
  computed,
} from '@vue/composition-api'
import ability from '@/plugins/acl/ability'
import notificationService from '@/services/notification.service'
import receiptStoreModule from '../receipt/receiptStoreModule'

export default {
  setup() {
    // Initializers
    const RECEIPT_APP_STORE_MODULE_NAME = 'app-receipt'
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')
    const isAuditor = ability.can('read', 'ReportSummaries')
    const format = isAuditor ? 'csv' : 'pdf'

    if (!store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) {
      store.registerModule(RECEIPT_APP_STORE_MODULE_NAME, receiptStoreModule)
    }

    // Table Handlers
    const tableColumns = computed(() => (
      [
        { text: t('Actions'), value: 'actions', align: 'center' },
        { text: t('SMS Last Sent'), value: 'sms_sent_at' },
        { text: t('#Receipt No.'), value: 'receipt_number' },
        { text: t('Issue Date'), value: 'issued_at' },
        { text: t('Pickup Location'), value: 'pickup_location' },
        { text: t('Car Registration No.'), value: 'car_registration_number' },
        { text: t('Customer Name'), value: 'customer_name' },
        { text: t('Contact No.'), value: 'customer_contact_number' },
        { text: t('Insurance Premium'), align: 'right', value: 'insurance_premium' },
        { text: t('After Discount'), align: 'right', value: 'after_discount' },
        { text: t('Voucher Discount'), align: 'right', value: 'voucher_amount' },
        { text: t('Voucher Code'), align: 'right', value: 'voucher_code' },
        { text: t('Net Payment'), align: 'right', value: 'net_payment' },
        { text: t('SPD Reference No.'), align: 'right', value: 'full_spd_number' },
        { text: t('Road Tax'), align: 'right', value: 'road_tax' },
        { text: t('Additional Fees'), align: 'right', value: 'additional_fees' },
        { text: t('Payment Method'), value: 'payment_method' },
        { text: t('#Invoice No.'), value: 'invoice_number' },
        { text: t('General Remarks'), value: 'general_remarks' },
      ]
    ))
    const tableSmColumns = computed(() => (
      [
        { text: t('#Receipt No.'), value: 'receipt_number' },
        { text: t('SMS Last Sent'), value: 'sms_sent_at' },
      ]
    ))

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const receipts = ref([])
    const selectedReceipts = ref([])
    const excludedReceipts = ref([])
    const selectAll = ref(false)
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const searchText = ref('')
    const options = ref({
      sortBy: ['id'],
      sortDesc: [false],
    })
    const expanded = ref([])
    const loading = ref(false)
    const loadingPrint = ref(false)
    const loadingDownload = ref(false)
    const branchOptions = ref([
      { name: 'Ongoing branches', value: 'active' },
      { name: 'Closed branches', value: 'inactive' },
    ])
    const toggleSelected = ref('active')
    const branchFilters = ref([])
    const insuranceFilters = ref([])
    const accountFilters = ref([])
    const sendFilters = ref([
      { id: 'sent', name: 'Show sent SMS' },
      { id: 'unsent', name: 'Show unsent SMS' },
    ])
    const smsSent = ref(null)
    const dateRange = ref([`${yearNow}-${monthNow}-${dayNow}`])
    const listLengthOptions = ref([20, 50, 100])
    const isDateMenuOpen = ref(false)
    const accounts = ref([])
    const pdfCount = ref(null)
    const receiptScreenshot = ref({})
    const receiptSms = ref({})
    const sendLoading = ref(false)
    const isScreenshotDialogVisible = ref(false)
    const isSendSmsDialogVisible = ref(false)
    const filterBySentSms = ref(false)

    // Utils
    const formatDate = date => {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    }

    // Computed
    const branches = computed(() => {
      switch (toggleSelected.value) {
        case 'active':
          return store.state.branches.filter(b => b.active)
        case 'inactive':
          return store.state.branches.filter(b => b.active === false)
        default:
          return store.state.branches
      }
    })
    const insurances = computed(() => store.state.insurances)
    const dateRangeText = computed(() => {
      if (Date.parse(dateRange.value[0]) > Date.parse(dateRange.value[1])) {
        const [start, end] = dateRange.value
        dateRange.value[0] = end
        dateRange.value[1] = start
      }

      const startDateText = formatDate(dateRange.value[0])
      const showEndDate = dateRange.value[0] !== dateRange.value[1] && formatDate(dateRange.value[1]) !== null
      const endDateText = showEndDate ? ` - ${formatDate(dateRange.value[1])}` : ''

      return `${startDateText}${endDateText}`
    })

    // Methods
    const isLoading = () => {
      loading.value = true
      snackbarService.info('Loading your list. Please hold...')
    }
    const doneLoading = () => {
      loading.value = false
      snackbarService.success('Done loading your list!')
    }
    const fetchReceipts = () => {
      const searchParams = {
        page: tablePagination.value.current_page,
        per: tablePagination.value.per,
        search_text: searchText.value,
        user_ids: accountFilters.value.map(account => account.id),
        branch_ids: branchFilters.value.map(branch => branch.id),
        insurance_ids: insuranceFilters.value.map(insurance => insurance.id),
        with_roadtax: true,
        sms_sent: smsSent.value,
        branch_toggle_filter: toggleSelected.value,
      }
      const startDate = dateRange.value[0]
      const endDate = dateRange.value[1] ? dateRange.value[1] : dateRange.value[0]
      if (filterBySentSms.value) {
        searchParams.sms_start_date = startDate
        searchParams.sms_end_date = endDate
      } else {
        searchParams.start_date = startDate
        searchParams.end_date = endDate
      }
      store
        .dispatch('app-receipt/fetchReceipts', searchParams)
        .then(response => {
          const { data, pagination } = response.data
          receipts.value = data
          receipts.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (key === 'net_payment' || key === 'sms_sent_at') return
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          if (selectAll.value) {
            receipts.value.forEach(receipt => {
              if (!excludedReceipts.value.includes(receipt.id)) selectedReceipts.value.push(receipt)
            })
          }
          tablePagination.value = pagination
          pdfCount.value = response.data.pdf_count
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching receipts. Please refresh!')
        })
    }
    const pageChange = () => {
      isLoading()
      fetchReceipts()
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      isLoading()
      fetchReceipts()
    }
    const dateRangeChange = () => {
      isDateMenuOpen.value = false
      tablePagination.value.current_page = 1
      isLoading()
      fetchReceipts()
    }
    const activeToggleChange = () => {
      branchFilters.value = []
    }
    const printRunnerReport = () => {
      loadingPrint.value = true
      const receiptIds = []
      selectedReceipts.value.forEach(receipt => {
        receiptIds.push(receipt.id)
      })
      const searchParams = {
        search_text: searchText.value,
        user_ids: accountFilters.value.map(account => account.id),
        branch_ids: branchFilters.value.map(branch => branch.id),
        insurance_ids: insuranceFilters.value.map(insurance => insurance.id),
        receipt_ids: receiptIds,
        excluded_receipt_ids: excludedReceipts.value,
        select_all: selectAll.value,
        sms_sent: smsSent.value,
        mode: 'runner_report',
        branch_toggle_filter: toggleSelected.value,
        format,
      }
      const startDate = dateRange.value[0]
      const endDate = dateRange.value[1] ? dateRange.value[1] : dateRange.value[0]
      if (filterBySentSms.value) {
        searchParams.sms_start_date = startDate
        searchParams.sms_end_date = endDate
      } else {
        searchParams.start_date = startDate
        searchParams.end_date = endDate
      }
      store
        .dispatch('app-receipt/printReport', searchParams)
        .then(response => {
          if (response.type === 'application/json') {
            response.text().then(res => {
              const error = JSON.parse(res)
              snackbarService.error(`${error.message} ${error.receipt_ids || ''}`, -1)
            })
          } else {
            const url = window.URL.createObjectURL(response)
            const a = document.createElement('a')
            a.href = url
            const filename = filterBySentSms.value ? 'runner-sms-send' : 'runner-receipt'
            a.download = `${filename}(${dateRangeText.value}).${format}`
            document.body.appendChild(a)
            a.click()
            a.remove()
          }
          loadingPrint.value = false
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while printing report. Please refresh!')
        })
    }
    const fetchClerks = () => {
      store
        .dispatch('app-receipt/fetchClerks', { role: [1, 4, 6] })
        .then(response => {
          const { data } = response.data
          accounts.value = data
        })
        .catch(error => {
          const timeout = error.response.data.errors[0] === 'no-timeout' ? 0 : 5000
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching accounts. Please refresh!', timeout)
        })
    }
    const downloadScreenshot = receipt => {
      fetch(receipt.payment_screenshot_url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `${receipt.receipt_number}_${receipt.issued_at.split('/').join('-')}_${receipt.payment_screenshot_name}`
          link.click()
        })
        .catch(() => {
          snackbarService.error('Something went wrong while downloading screenshot. Please refresh!')
        })
    }
    const sendRoadtaxMessage = () => {
      sendLoading.value = true
      store
        .dispatch('app-receipt/sendSMS', { id: receiptSms.value.id, sms_template: 'roadtax' })
        .then(response => {
          snackbarService.success(response.data.message)
          sendLoading.value = false
          isSendSmsDialogVisible.value = false
        })
        .catch(error => {
          sendLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while sending message. Please refresh!')
        })
    }
    const selectedChange = event => {
      if (selectAll.value) {
        if (!event.value) {
          excludedReceipts.value.push(event.item.id)
        } else {
          const index = excludedReceipts.value.indexOf(event.item.id)
          if (index > -1) excludedReceipts.value.splice(index, 1)
        }
      }
    }
    const toggleAll = event => {
      if (!event.value) {
        selectedReceipts.value = []
        excludedReceipts.value = []
        selectAll.value = false
      } else {
        excludedReceipts.value = []
        selectAll.value = true
      }
    }
    const searchReceipts = () => {
      isLoading()
      fetchReceipts()
    }
    const expandRow = (item, event) => {
      if (isSendSmsDialogVisible.value) return

      expanded.value = []
      if (event.isExpanded) {
        const indexExpanded = expanded.value.findIndex(i => i === item)
        expanded.value.splice(indexExpanded, 1)
      } else {
        expanded.value.push(item)
      }
    }
    const updateLastSent = () => {
      if (can('write', 'Runner')) {
        notificationService.subscribeToRunner({
          connected() {
            console.log('Connected to the runner channel')
          },
          received(data) {
            const receipt = receipts.value.find(r => r.id === data.id)
            receipt[data.attr] = data.value
          },
        })
      }
    }

    onMounted(() => {
      fetchClerks()
      searchReceipts()
      updateLastSent()
    })

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      can,
      loading,
      loadingPrint,
      receipts,
      selectedReceipts,
      accounts,
      branches,
      insurances,
      tablePagination,
      tableColumns,
      tableSmColumns,
      expanded,
      isAuditor,
      isDateMenuOpen,
      listLengthOptions,
      options,
      searchText,
      branchOptions,
      branchFilters,
      insuranceFilters,
      accountFilters,
      sendFilters,
      smsSent,
      dateRange,
      dateRangeText,
      fetchReceipts,
      pageChange,
      listLengthChange,
      dateRangeChange,
      activeToggleChange,
      formatDate,
      printRunnerReport,
      loadingDownload,
      pdfCount,
      receiptScreenshot,
      receiptSms,
      sendLoading,
      isScreenshotDialogVisible,
      isSendSmsDialogVisible,
      filterBySentSms,
      downloadScreenshot,
      sendRoadtaxMessage,
      selectedChange,
      toggleAll,
      searchReceipts,
      expandRow,
      toggleSelected,

      icons: {
        mdiPlus,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiCalendar,
        mdiPrinter,
        mdiMagnify,
        mdiClose,
        mdiFileDocumentOutline,
        mdiHelpCircleOutline,
        mdiPaperclip,
        mdiDownload,
        mdiOpenInNew,
        mdiMessageProcessingOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  #receipt-list {
    .receipt-list-search {
      width: 32.5rem;
    }

    .receipt-list-insurances {
      max-width: 33rem;
    }

    .receipt-list-branch {
      max-width: 33rem;
    }

    .receipt-list-account {
      max-width: 33rem;
    }

    .receipt-list-row-selection {
      max-width: 5.3rem;
    }

    .receipt-payment {
      min-width: 10rem;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      .receipt-payment {
        width: 100px;
      }
    }
  }

  .table-sm-item {
    font-size: 12px;
    font-weight: 700;
  }

  .receipt-button {
    width: 251px;
  }

  .payment-screenshot-viewer {
    height: 80vh;
    width: 100%;
  }

  ::v-deep .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg.loading-color {
    background: #d3d3d3 !important;
    color: #eee !important;
  }

  .dark-divider {
    border-right: 1px solid rgba(231, 227, 252, 0.14) !important;
  }

  .light-divider {
    border-right: 1px solid rgba(94, 86, 105, 0.14);
  }
</style>

<style>
.v-card__actions {
  display: block !important;
}
</style>
